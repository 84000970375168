/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCancelledTransactionCommand } from './CreateCancelledTransactionCommand';
import type { CreateCardInfoCommand } from './CreateCardInfoCommand';
import type { CreateCashPayCommand } from './CreateCashPayCommand';
import type { CreateCheckinTransactionCommand } from './CreateCheckinTransactionCommand';
import type { CreateEpPayCommand } from './CreateEpPayCommand';
import type { CreateGreenListCommand } from './CreateGreenListCommand';
import type { CreateTicketCommand } from './CreateTicketCommand';
import type { TransDataDto } from './TransDataDto';
export type CreateVehicleTransactionCommand = {
  devId: number;
  vehId: number;
  drvId: number;
  shftId: number;
  trxDT: string;
  dayType?: CreateVehicleTransactionCommand.dayType;
  trxId: number;
  trxType: number;
  lineNo: number;
  tripNo: number;
  tripNo2?: number;
  stnId: number;
  stnFromName?: string;
  stnTripOrd: number;
  stnZon: string;
  currency: string;
  trxPrice: number;
  result: number;
  cashPay?: CreateCashPayCommand;
  ePPay?: CreateEpPayCommand;
  cardInfo?: CreateCardInfoCommand;
  payMeans: number;
  /**
   * Transaction tickets.
   */
  tickets?: Array<CreateTicketCommand>;
  /**
   * Cancelled transaction.
   */
  cancelledTrx?: CreateCancelledTransactionCommand;
  /**
   * Checkin transactions for this transaction that is a check-out.
   */
  checkInTrx?: Array<CreateCheckinTransactionCommand>;
  greenList?: CreateGreenListCommand;
  transData?: TransDataDto;
};
export namespace CreateVehicleTransactionCommand {
  export enum dayType {
    WORK_DAY = 'WorkDay',
    FREE_DAY = 'FreeDay',
    SUNDAY = 'Sunday',
    HOLIDAY = 'Holiday',
    '_1' = 1,
    '_2' = 2,
    '_3' = 3,
    '_4' = 4,
  }
}
