import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Paths from '@/constants/Paths';
import store from '@/store';
import router from '@/router';
import { SystemConfigurationDto } from '@/api';
import { PaymentGatewayTypes } from '@/constants/PaymentGatewayTypes';
import { Currencies } from '@common/core/Currency';
import { MIGRATION_FLAGS } from '@T-system/common/src/api/constants';

const _systemConfiguration: SystemConfigurationDto = {
  id: '',
  eshopMultiLocalizationEnabled: false,
  cardApplicationFilesUploadEnabled: false,
  virtualCardEWalletEnabled: false,
  eshopFooterManualEnabled: false,
  SMTPPermeabilityEnabled: false,
  bankCardAsCarrier: false,
  virtualCardAsCarrier: false,
  showTaxOnReceipt: false,
  ticketMinValidFrom: '',
  haulierTokenKey: '',
  refundReasonEnabled: false,
  showSimpleCardApplication: false,
  allowFreeCardApplication: false,
  paymentGateway: '',
  EETLocalization: '',
  currency: '',
  eshopAllowedPaymentTypes: [],
  counterAllowedPaymentTypes: [],
  allowCameraInCardApplication: false,
  allowShortTermTickets: false,
  defaultLanguage: '',
  migrationFlags: [],
  haulierCardAsCarrier: false,
  marketingConsentCardEnabled: false,
  addressStreetRequired: false,
  addressStreetNumberRequired: false,
  addressCityRequired: false,
  addressZipCodeRequired: false,
  ticketCancellationFinalizationEnabled: false,
  priceListUseActualDateInsteadValidFrom: false,
  eshopReclamationEnabled: false,
  synchronizationWithExternalEnabled: false,
  allowIsic: false,
  assignCardWithoutAgeRestriction: false,
  allowCustomerCardGroup: false,
  haulierCardWLAsCarrier: false,
  allowBankCardApplicationFromCounter: false,
  allowCloseEshopToastOnlyUsingButton: false,
  allowPersistEshopSideMenu: false,
  allowFirstCardApplicationWithDiscountProfileForFree: false,
  disableDeleteAccountWithActiveTicketsAndEP: false,
  allowUpdateCardValidity: false,
};

export interface SystemConfigurationPayload {
  redirect: boolean;
  systemConfiguration: SystemConfigurationDto;
}

@Module({ dynamic: true, store, namespaced: true, name: 'systemConfiguration' })
class SystemConfiguration extends VuexModule {
  public configuration: SystemConfigurationDto = _systemConfiguration;

  @Mutation
  public setSystemConfiguration(payload: SystemConfigurationPayload): void {
    this.configuration = payload.systemConfiguration;

    if (payload.redirect) {
      const { redirectUrl, token } = router.currentRoute.value.query;
      if (redirectUrl) {
        const path = token ? `${redirectUrl.toString()}?token=${token}` : redirectUrl.toString();
        router.push(path);
      } else {
        router.push(Paths.USER_ACCOUNT);
      }
    }
  }

  @Mutation
  public clearSystemConfiguration(): void {
    this.configuration = _systemConfiguration;
  }

  public get allowedPaymentTypes() {
    return this.configuration.eshopAllowedPaymentTypes;
  }

  public get currency() {
    return Object.values(Currencies).find(currency => currency.Code === this.configuration.currency);
  }

  public get cardApplicationAttachmentAllowed() {
    return this.configuration.cardApplicationFilesUploadEnabled;
  }

  public get isCsobPaymentGateway() {
    return this.configuration.paymentGateway === PaymentGatewayTypes.CSOB;
  }

  public get isGpPaymentGateway() {
    return this.configuration.paymentGateway === PaymentGatewayTypes.GP;
  }

  public get isVirtualCardEWalletAllowed() {
    return this.configuration.virtualCardEWalletEnabled;
  }

  public get isAllowedFreeCardApplication() {
    return this.configuration.allowFreeCardApplication;
  }

  public get isBankCardAsCarrierAllowed() {
    return this.configuration.bankCardAsCarrier;
  }

  public get isVirtualCardAsCarrierAllowed() {
    return this.configuration.virtualCardAsCarrier;
  }

  public get isTelshopMigrationAllowed() {
    return (
      this.configuration.migrationFlags.length && this.configuration.migrationFlags.includes(MIGRATION_FLAGS.TELSHOP)
    );
  }

  public get isHaulierCardAsCarrierAllowed() {
    return this.configuration.haulierCardAsCarrier;
  }

  public get isIsicAllowed() {
    return this.configuration.allowIsic;
  }

  public get isSynchronizationWithExternalAllowed() {
    return this.configuration.synchronizationWithExternalEnabled;
  }

  public get isAssignCardWithoutAgeRestrictionAllowed() {
    return this.configuration.assignCardWithoutAgeRestriction;
  }

  public get isCloseToastUsingButtonAllowed() {
    return this.configuration.allowCloseEshopToastOnlyUsingButton;
  }

  public get isPersistSideMenuAllowed() {
    return this.configuration.allowPersistEshopSideMenu;
  }

  public get isDisableDeleteAccountAllowed() {
    return this.configuration.disableDeleteAccountWithActiveTicketsAndEP;
  }
}

export default getModule(SystemConfiguration);
